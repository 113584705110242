import React, { memo } from "react";
import {
  ClassifySubHeaderSlideWrapper,
  ClassifySubHeaderWrapper,
} from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { currValue } from "../../../utils";

const KTClassifySubHeader = memo(
  ({
    items,
    selectedItem,
    onSelectedItemClick,
    windowWidth = window.screen.width,
  }) => {
    const swiperSlides = items.map((item) => {
      return (
        <SwiperSlide
          className="swiperSlide"
          onClick={() => onSelectedItemClick(item)}
        >
          {/* <ClassifySubHeaderSlideWrapper>
            <div
              className={classNames("title", {
                titleSelected: item.title === selectedItem.title,
              })}
            >
              {item.title}
            </div>
            <div className="emoji">
              {item.title === selectedItem.title ? item.emoji : null}
            </div>
          </ClassifySubHeaderSlideWrapper> */}
        </SwiperSlide>
      );
    });

    return (
      <ClassifySubHeaderWrapper>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={windowWidth > 480 ? "60px" : "33rem"}
        >
          {swiperSlides}
        </Swiper>
      </ClassifySubHeaderWrapper>
    );
  }
);

export default KTClassifySubHeader;
